import {
  AccountCircleTwoTone,
  CardTravelTwoTone,
  FlagTwoTone,
  HomeWorkTwoTone,
  MapTwoTone,
  NotificationsActiveTwoTone,
  PhonelinkSetupTwoTone,
  StorefrontTwoTone,
  Tune,
  VpnKeyTwoTone,
} from '@mui/icons-material';
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { clsx } from 'clsx';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

const drawerWidth = 240;

const paths = {
  allowLists: 'allow_lists',
  setupDocuments: 'setup_documents',
  companies: 'companies',
  organizations: 'organizations',
  announcements: 'announcements',
  operators: 'operators',
  plans: 'plans',
  gendaApiKeys: 'genda_api_keys',
  featureFlags: 'feature_flags',
  uberOrganizations: 'uber_organizations',
} as const;

const useStyles = makeStyles((theme) =>
  createStyles({
    drawer: {
      gridArea: 'menu',
      width: drawerWidth,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    drawerContainer: {
      overflow: 'auto',
    },
    drawerList: {
      padding: 0,
    },
  }),
);

export const SideMenu = () => {
  const router = useRouter();
  const classes = useStyles();
  const menu = router.pathname.split('/')[1];
  const open = true;
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const elem = e.currentTarget;

      if (!elem) return;

      const path = elem.dataset['path'];

      if (!path) return;

      router.push(`/${path}`);
    },
    [router],
  );

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List className={classes.drawerList}>
          <ListItemButton
            selected={paths.setupDocuments === menu}
            data-path={paths.setupDocuments}
            onClick={handleClick}
          >
            <ListItemIcon>
              <Tune />
            </ListItemIcon>
            <ListItemText primary="初期セットアップ" />
          </ListItemButton>
          <ListItemButton
            selected={paths.companies === menu}
            data-path={paths.companies}
            onClick={handleClick}
          >
            <ListItemIcon>
              <HomeWorkTwoTone />
            </ListItemIcon>
            <ListItemText primary="本部一覧" />
          </ListItemButton>
          <ListItemButton
            selected={paths.organizations === menu}
            data-path={paths.organizations}
            onClick={handleClick}
          >
            <ListItemIcon>
              <StorefrontTwoTone />
            </ListItemIcon>
            <ListItemText primary="店舗検索" />
          </ListItemButton>
          <ListItemButton
            selected={paths.announcements === menu}
            data-path={paths.announcements}
            onClick={handleClick}
          >
            <ListItemIcon>
              <NotificationsActiveTwoTone />
            </ListItemIcon>
            <ListItemText primary="お知らせ" />
          </ListItemButton>
          <ListItemButton
            selected={paths.plans === menu}
            data-path={paths.plans}
            onClick={handleClick}
          >
            <ListItemIcon>
              <CardTravelTwoTone />
            </ListItemIcon>
            <ListItemText primary="プラン" />
          </ListItemButton>
          <ListItemButton
            selected={paths.gendaApiKeys === menu}
            data-path={paths.gendaApiKeys}
            onClick={handleClick}
          >
            <ListItemIcon>
              <VpnKeyTwoTone />
            </ListItemIcon>
            <ListItemText primary="外部連携APIキー" />
          </ListItemButton>
          <ListItemButton
            selected={paths.operators === menu}
            data-path={paths.operators}
            onClick={handleClick}
          >
            <ListItemIcon>
              <AccountCircleTwoTone />
            </ListItemIcon>
            <ListItemText primary="オペレータ" />
          </ListItemButton>
          {process.env.enableUseAllowList && (
            <ListItemButton
              selected={paths.allowLists === menu}
              data-path={paths.allowLists}
              onClick={handleClick}
            >
              <ListItemIcon>
                <PhonelinkSetupTwoTone />
              </ListItemIcon>
              <ListItemText primary="許可済み電話番号" />
            </ListItemButton>
          )}
          <ListItemButton
            selected={paths.featureFlags === menu}
            data-path={paths.featureFlags}
            onClick={handleClick}
          >
            <ListItemIcon>
              <FlagTwoTone />
            </ListItemIcon>
            <ListItemText primary="フィーチャーフラグ" />
          </ListItemButton>
          <ListItemButton
            selected={paths.uberOrganizations === menu}
            data-path={paths.uberOrganizations}
            onClick={handleClick}
          >
            <ListItemIcon>
              <MapTwoTone />
            </ListItemIcon>
            <ListItemText primary="当日配達エリア判定" />
          </ListItemButton>
        </List>
      </div>
    </Drawer>
  );
};
