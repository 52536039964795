import { format as fnsFormat } from 'date-fns';
import { ja } from 'date-fns/locale';

export const format = (date: Date | string | number, withTime = false) => {
  const formatString = withTime ? 'yyyy/MM/dd HH:mm' : 'yyyy/MM/dd';

  if (typeof date === 'string') {
    return fnsFormat(new Date(date), formatString, { locale: ja });
  } else {
    return fnsFormat(date, formatString, { locale: ja });
  }
};

export const formatISOString = (date: Date | string | number | null) => {
  const formatString = "yyyy-MM-dd'T'HH:mm:ssxx";

  if (typeof date === 'string') {
    return fnsFormat(new Date(date), formatString, { locale: ja });
  } else if (date === null) {
    return null;
  } else {
    return fnsFormat(date, formatString, { locale: ja });
  }
};

export const formatTerm = (
  startAt?: Date | string | number | null,
  endAt?: Date | string | number | null,
  withTime = false,
) => {
  if (startAt && endAt) {
    const formatedStartAt = format(startAt, withTime);
    const formatedEndAt = format(endAt, withTime);

    return `${formatedStartAt} 〜 ${formatedEndAt}`;
  } else if (startAt) {
    const formatedStartAt = format(startAt, withTime);

    return `${formatedStartAt} 〜`;
  } else if (endAt) {
    const formatedEndAt = format(endAt, withTime);

    return `〜 ${formatedEndAt}`;
  } else {
    return '';
  }
};

/**
 * input[type=date]のvalueに適合した書式に変換した文字列を返す
 * @param value ISO8601書式の文字列か、new Dateが受け付ける書式の文字列
 * */
export const formatYYYYMMDD = (value: string) => {
  return fnsFormat(new Date(value), 'yyyy-MM-dd');
};
