import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { AlertMessae } from '~/components/partials';
import { GetCompanySetupDocumentsDocument, useRemoveCompanySetupDocumentMutation } from '~/graphql';
import { deleteModalState, setupDocumentsPageInfoState } from '~/state/setup_documents/atoms';

export const DeleteModal = () => {
  const [state, setState] = useRecoilState(deleteModalState);
  const { page, perPage } = useRecoilValue(setupDocumentsPageInfoState);
  const [removeCompanySetupDocumentMutation, { loading, error }] =
    useRemoveCompanySetupDocumentMutation({
      onCompleted: () => {
        setState({
          isOpen: false,
          setupDocumentsCompanyId: null,
        });
      },
      refetchQueries: [
        { query: GetCompanySetupDocumentsDocument, variables: { done: false, page, perPage } },
      ],
    });

  const handleDeleteClick = useCallback(
    (companySetupDocumentId: string | null) => {
      if (companySetupDocumentId) {
        removeCompanySetupDocumentMutation({
          variables: {
            companySetupDocumentId: companySetupDocumentId,
          },
        });
      }
    },
    [removeCompanySetupDocumentMutation],
  );

  const handleClose = useCallback(() => {
    setState({
      isOpen: false,
      setupDocumentsCompanyId: null,
    });
  }, [setState]);

  return (
    <Dialog open={state.isOpen}>
      <DialogTitle>セットアップURL削除</DialogTitle>
      <DialogContent>
        {error && <AlertMessae>{error.message}</AlertMessae>}
        <Box m={1}>セットアップURLを削除しますか？</Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button
          variant="contained"
          disabled={loading}
          onClick={() => handleDeleteClick(state.setupDocumentsCompanyId)}
        >
          削除する
        </Button>
      </DialogActions>
    </Dialog>
  );
};
