import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import { AppHeader } from '~/components/partials';

import { SideMenu } from './SideMenu';

type Props = {
  children?: React.ReactNode;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateAreas: `
        "header header"
        "menu main"
      `,
      height: '100vh',
      width: '100vw',
      gridTemplateColumns: 'min-content 1fr',
      gridTemplateRows: 'min-content 1fr',
      overflow: 'hidden',
    },
    header: {
      gridArea: 'header',
    },
    main: {
      gridArea: 'main',
      overflow: 'auto',
    },
  }),
);

export const AppShell: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <AppHeader />
      </div>
      <SideMenu />
      <main className={classes.main}>{children}</main>
    </div>
  );
};
