import { Box, Pagination } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { ExpandTable } from '~/components/blocks';
import { TableEmptyMessage } from '~/components/partials';
import {
  CompanySetupDocumentFragment,
  GetCompanySetupDocumentsQuery,
  useGetCompanySetupDocumentsLazyQuery,
} from '~/graphql';
import { setupDocumentsPageInfoState } from '~/state/setup_documents/atoms';

import { SetupDocument } from './SetupDocument';

const getTotalPage = (data: GetCompanySetupDocumentsQuery) => {
  return data.companySetupDocuments.pagesCount;
};

export const SetupDocumentList = () => {
  const [state, setState] = useRecoilState(setupDocumentsPageInfoState);
  const [getSetupDocuments, { data, loading }] = useGetCompanySetupDocumentsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (result) => {
      setState((state) => ({ ...state, totalPage: getTotalPage(result) }));
    },
  });
  const setupDocuments = useMemo(
    () =>
      (data?.companySetupDocuments.nodes
        ?.map((d) => d)
        .filter(Boolean) as CompanySetupDocumentFragment[]) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.companySetupDocuments?.nodes],
  );

  const handleChangePage = useCallback(
    (_: React.ChangeEvent<unknown>, page: number) => setState((state) => ({ ...state, page })),
    [setState],
  );

  useEffect(() => {
    getSetupDocuments({
      variables: { done: false, page: state.page, perPage: state.perPage },
    });
  }, [getSetupDocuments, state.page, state.perPage]);

  return (
    <>
      <ExpandTable
        title={['本部名', '窓口担当', '発行日', '有効期限', 'メール送信状況']}
        loading={loading}
      >
        {setupDocuments.map((d) => (
          <SetupDocument key={d.id} setupDocument={d} />
        ))}
      </ExpandTable>

      {setupDocuments.length === 0 ? (
        <TableEmptyMessage />
      ) : (
        <Box marginTop={1}>
          <Pagination
            page={state.page}
            count={state.totalPage}
            variant="outlined"
            shape="rounded"
            onChange={handleChangePage}
          />
        </Box>
      )}
    </>
  );
};
