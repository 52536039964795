import { parsePhoneNumber } from 'awesome-phonenumber';
import IsEmail from 'isemail';
import { object, ObjectSchema, string } from 'yup';

import { FormErrorMessage } from '~/constants/messages';
import { katakanaRegexp } from '~/constants/regexp';

import { Fields } from './types';

export const validationSchema: ObjectSchema<Fields> = object({
  companyName: string().trim().required(FormErrorMessage.required),
  organizationName: string().trim().required(FormErrorMessage.required),
  practitionerFamilyName: string().trim().required(FormErrorMessage.required),
  practitionerGivenName: string().trim().required(FormErrorMessage.required),
  practitionerPhoneticFamilyName: string()
    .required(FormErrorMessage.required)
    .matches(katakanaRegexp, FormErrorMessage.katakana),
  practitionerPhoneticGivenName: string()
    .required(FormErrorMessage.required)
    .matches(katakanaRegexp, FormErrorMessage.katakana),
  practitionerEmail: string()
    .required(FormErrorMessage.required)
    .test('practitionerEmail', FormErrorMessage.emailInvalid, (email) => {
      if (!email) return false;
      return IsEmail.validate(email);
    }),
  practitionerTel: string()
    .required(FormErrorMessage.required)
    .test('practitionerTel', FormErrorMessage.telInvalid, (tel) => {
      if (!tel) return false;

      const phoneNumber = parsePhoneNumber(tel, { regionCode: 'JP' });
      return phoneNumber.valid;
    }),
  contractCount: string()
    .trim()
    .required(FormErrorMessage.required)
    .test('契約店舗数', '不正な値です', (v) => {
      if (!v) return false;
      return +v >= 0;
    }),
  contractPlanId: string().label('契約プラン').required(FormErrorMessage.required),
  contractStartDate: string().label('契約開始日').required(FormErrorMessage.required),
});
