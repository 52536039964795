import { Box, Button } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';

import { ClipButton, DefinitionItem, DefinitionList, ExpandTableRow } from '~/components/blocks';
import { AlertMessae, MailStatus } from '~/components/partials';
import { CompanySetupDocumentFragment } from '~/graphql';
import { useOperatorAuthority } from '~/hooks/use-operator-authority';
import { deleteModalState, extendExpiresModalState } from '~/state/setup_documents/atoms';
import { format } from '~/utils/date';

import { ExpirationStatus } from '../ExpirationStatus';

type Props = {
  setupDocument: CompanySetupDocumentFragment;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    url: {
      display: 'flex',
      alignItems: 'center',
    },
    copyUrl: {
      marginLeft: theme.spacing(1),
    },
    actions: {
      textAlign: 'right',
      '& > button + button': {
        marginLeft: theme.spacing(1),
      },
    },
  }),
);

export const SetupDocument = (props: Props) => {
  const { id, data, token, expiresAt, createdAt, sendgridEventActivities } = props.setupDocument;
  const {
    company_name,
    organization_name,
    practitioner_family_name,
    practitioner_given_name,
    practitioner_phonetic_family_name,
    practitioner_phonetic_given_name,
    practitioner_email,
    practitioner_tel,
    contract_count,
    contract_plan_name,
    contract_start_date,
  } = data as any; // eslint-disable-line @typescript-eslint/no-explicit-any
  const creationDate = format(createdAt, true);
  const adminName = `${practitioner_family_name}${practitioner_given_name}(${practitioner_phonetic_family_name}${practitioner_phonetic_given_name})`;
  const classes = useStyles();
  const setupUrl = `${process.env.pharmsRootUrl}/signup?token=${token}`;
  const isExpired = useMemo(() => {
    return expiresAt ? new Date() > new Date(expiresAt) : true;
  }, [expiresAt]);
  const mailStatus = sendgridEventActivities ? sendgridEventActivities[0]?.status : undefined;

  const [hasUpdateAuthorizationed] = useOperatorAuthority('company_setup_documents', 'update');
  const [hasDestroyAuthorizationed] = useOperatorAuthority('company_setup_documents', 'destroy');

  const setExtendExpiresState = useSetRecoilState(extendExpiresModalState);
  const setDeleteModalState = useSetRecoilState(deleteModalState);

  const handleOpenExtendExpiresModal = useCallback(() => {
    setExtendExpiresState({
      isOpen: true,
      setupDocumentsCompanyId: id,
    });
  }, [id, setExtendExpiresState]);

  const handleOpenDeleteModal = useCallback(() => {
    setDeleteModalState({
      isOpen: true,
      setupDocumentsCompanyId: id,
    });
  }, [id, setDeleteModalState]);

  return (
    <ExpandTableRow
      items={[
        company_name,
        adminName,
        creationDate,
        <ExpirationStatus key={4} expired={isExpired} />, // 配列のelementにはkey propsが必要
        <MailStatus key={5} status={mailStatus} />, // 同上
      ]}
    >
      {isExpired && <AlertMessae>有効期限が過ぎています</AlertMessae>}
      <DefinitionList>
        <DefinitionItem term="セットアップURL">
          <div className={classes.url}>
            {setupUrl}
            <ClipButton className={classes.copyUrl} text={setupUrl} />
          </div>
        </DefinitionItem>
        <DefinitionItem term="有効期限">
          {expiresAt ? format(expiresAt, true) : '未設定'}
        </DefinitionItem>
        <DefinitionItem term="店舗名">{organization_name}</DefinitionItem>
        <DefinitionItem term="契約店舗数">{contract_count ?? '未登録'}</DefinitionItem>
        <DefinitionItem term="契約プラン">{contract_plan_name || '未登録'}</DefinitionItem>
        <DefinitionItem term="契約開始日">
          {contract_start_date ? format(contract_start_date) : '未登録'}
        </DefinitionItem>
        <DefinitionItem term="窓口担当">{adminName}</DefinitionItem>
        <DefinitionItem term="メールアドレス">{practitioner_email || '未登録'}</DefinitionItem>
        <DefinitionItem term="電話番号">{practitioner_tel || '未登録'}</DefinitionItem>
      </DefinitionList>
      {(hasDestroyAuthorizationed || hasUpdateAuthorizationed) && (
        <Box mt={1} className={classes.actions}>
          {hasDestroyAuthorizationed && (
            <Button color="secondary" variant="contained" onClick={handleOpenDeleteModal}>
              セットアップURLを削除する
            </Button>
          )}
          {hasUpdateAuthorizationed && (
            <Button variant="contained" onClick={handleOpenExtendExpiresModal}>
              有効期限を延長する
            </Button>
          )}
        </Box>
      )}
    </ExpandTableRow>
  );
};
