import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { add } from 'date-fns';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import { GetCompanySetupDocumentsDocument, useExtendCompanySetupDocumentMutation } from '~/graphql';
import { extendExpiresModalState } from '~/state/setup_documents/atoms';
import { formatISOString } from '~/utils/date';

export const ExtendExpiresModal = () => {
  const [state, setState] = useRecoilState(extendExpiresModalState);
  const [extendCompanySetupDocument] = useExtendCompanySetupDocumentMutation({
    errorPolicy: 'all',
    onCompleted: () => {
      setState({
        isOpen: false,
        setupDocumentsCompanyId: null,
      });
    },
    refetchQueries: [{ query: GetCompanySetupDocumentsDocument }],
  });

  const handleExtendClick = useCallback(
    (companySetupDocumentId: string | null) => {
      if (companySetupDocumentId) {
        const newExpiresAt = add(new Date(), { weeks: 2 });
        extendCompanySetupDocument({
          variables: {
            input: {
              companySetupDocumentId,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              expiresAt: formatISOString(newExpiresAt)!,
            },
          },
        });
      }
    },
    [extendCompanySetupDocument],
  );

  const handleClose = useCallback(() => {
    setState({
      isOpen: false,
      setupDocumentsCompanyId: null,
    });
  }, [setState]);

  return (
    <Dialog open={state.isOpen}>
      <DialogTitle>有効期限の延長</DialogTitle>
      <DialogContent>本日から2週間、有効期限を延長します。</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button
          variant="contained"
          onClick={() => handleExtendClick(state.setupDocumentsCompanyId)}
        >
          延長する
        </Button>
      </DialogActions>
    </Dialog>
  );
};
