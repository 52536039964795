export const FormErrorMessage = {
  required: '必須入力項目です',
  emailInvalid: '入力形式が正しくありません',
  telInvalid: '入力形式が正しくありません',
  katakana: '全角カタカナを入力してください',
  mismatch: '${path}が一致しません',
  minLength: '${label}を${min}つ以上選択してください',
  alphanumeric: '英数字を入力してください',
  maxTextLength: '${max}文字以下で入力してください',
  uberSignatureStatusInvalid: '承認か差し戻しを選択してください',
};
