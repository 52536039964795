import { formatYYYYMMDD } from '~/utils/date';

import { Fields } from '../SetupDocumentForm/types';

export const useDefaultValues = (): Fields => {
  return {
    companyName: '',
    organizationName: '',
    practitionerFamilyName: '',
    practitionerGivenName: '',
    practitionerPhoneticFamilyName: '',
    practitionerPhoneticGivenName: '',
    practitionerEmail: '',
    practitionerTel: '',
    contractCount: '',
    contractPlanId: '',
    contractStartDate: formatYYYYMMDD(new Date().toISOString()),
  };
};
