import { Send } from '@mui/icons-material';
import { Button, Container } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback } from 'react';

import { SetupDocumentsTabs } from '~/components/partials';
import { useSetNewSetupDocumentModalVisiblityMutation } from '~/graphql';
import { useOperatorAuthority } from '~/hooks/use-operator-authority';

import { DeleteModal } from './DeleteModal';
import { ExtendExpiresModal } from './ExtendExpiresModal';
import { NewSetupDocumentModal } from './NewSetupDocumentModal';
import { SetupDocumentList } from './SetupDocumentList';

const useStyles = makeStyles((theme) =>
  createStyles({
    action: {
      marginTop: theme.spacing(2),
      maxWidth: 'inherit',
    },
    list: {
      margin: `${theme.spacing(4)} 0 0`,
      maxWidth: 'inherit',
      padding: `0 ${theme.spacing(2)}`,
    },
  }),
);

export const SetupDocuments = () => {
  const classes = useStyles();
  const [hasAuthorization] = useOperatorAuthority('company_setup_documents', 'create');
  const [openAddModal] = useSetNewSetupDocumentModalVisiblityMutation({
    variables: {
      isVisible: true,
    },
  });
  const handleAdd = useCallback(() => {
    openAddModal();
  }, [openAddModal]);

  return (
    <>
      <SetupDocumentsTabs />
      {hasAuthorization && (
        <Container className={classes.action}>
          <Button variant="contained" startIcon={<Send />} onClick={handleAdd}>
            セットアップURL発行
          </Button>
        </Container>
      )}
      <Container className={classes.list}>
        <SetupDocumentList />
      </Container>
      <NewSetupDocumentModal />
      <ExtendExpiresModal />
      <DeleteModal />
    </>
  );
};
