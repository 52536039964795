import { useMemo } from 'react';

import {
  OPERATOR_ROLE_ADMIN_NAME,
  OPERATOR_ROLE_ADMIN_RESOURCE_NAME,
} from '~/constants/operator_role';
import { OperatorRoleFragment, useGetSignedinOperatorQuery } from '~/graphql';
import { ActionName, Policy, ResourceName } from '~/types/operator_role';

type ResouceAction = Partial<Record<ActionName, boolean>>;

type OpeartorAbilities = Partial<Record<ResourceName, ResouceAction>>;

export const operatorAuthority = <R extends ResourceName>(
  operatorRoles: OperatorRoleFragment[] | null,
  resourceName: R,
  actionName: keyof Policy[R],
) => {
  if (!operatorRoles) return false;

  const isAdmin = operatorRoles.some(
    (or) =>
      or.name === OPERATOR_ROLE_ADMIN_NAME &&
      (or.abilities as OpeartorAbilities)[OPERATOR_ROLE_ADMIN_RESOURCE_NAME],
  );
  const abilities = Object.values(operatorRoles).flatMap((or) => or.abilities as OpeartorAbilities);
  const resouces = abilities.map((a) => a[resourceName]).filter(Boolean) as ResouceAction[];
  const enabledAction = resouces.some((r) =>
    Object.entries(r).some(
      ([_actionName, enabled]) =>
        (_actionName === 'admin' && enabled) || (_actionName === actionName && enabled),
    ),
  );

  return isAdmin || enabledAction;
};

export const useOperatorAuthority = <R extends ResourceName>(
  resourceName: R,
  actionName: keyof Policy[R],
) => {
  const { data, loading } = useGetSignedinOperatorQuery({ fetchPolicy: 'cache-only' });
  const operatorRoles =
    (data?.me?.__typename === 'Operator' ? data?.me?.operatorRoles : null) || null;
  const hasAuthorization = useMemo(
    () => operatorAuthority(operatorRoles, resourceName, actionName),
    [actionName, operatorRoles, resourceName],
  );
  // [認可, ローディング]
  return [hasAuthorization, loading];
};
