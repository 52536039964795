import { Chip } from '@mui/material';
import React from 'react';

type Props = {
  expired: boolean;
};

export const ExpirationStatus = (props: Props) => {
  return props.expired ? (
    <Chip size="small" variant="outlined" color="secondary" label="期限切れ" />
  ) : (
    <Chip size="small" variant="outlined" color="primary" label="期限内" />
  );
};
