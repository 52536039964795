import { Box, TextField as NativeTextField, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { forwardRef } from 'react';

import { TextField } from '~/components/blocks';
import { SelectPlan } from '~/components/partials';

import { Fields } from './types';
import { validationSchema } from './validation';

type Props = {
  loading: boolean;
  initialValue: Fields;
  onSubmit: (values: Fields, formikHelpers: FormikHelpers<Fields>) => void;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    name: {
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }),
);

export const SetupDocumentForm = forwardRef<FormikProps<Fields>, Props>((props, ref) => {
  const { onSubmit } = props;
  const classes = useStyles();

  return (
    <Formik
      innerRef={ref}
      initialValues={props.initialValue}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <div>
          <Box>
            <TextField
              fullWidth
              name="companyName"
              label="本部名"
              disabled={props.loading}
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={formik.errors.companyName}
            />
            <TextField
              fullWidth
              name="organizationName"
              label="店舗名"
              disabled={props.loading}
              value={formik.values.organizationName}
              onChange={formik.handleChange}
              error={formik.errors.organizationName}
            />
          </Box>
          <Box mt={2}>
            <Box className={classes.name}>
              <TextField
                name="practitionerFamilyName"
                label="姓"
                disabled={props.loading}
                value={formik.values.practitionerFamilyName}
                onChange={formik.handleChange}
                error={formik.errors.practitionerFamilyName}
              />
              <TextField
                name="practitionerGivenName"
                label="名"
                disabled={props.loading}
                value={formik.values.practitionerGivenName}
                onChange={formik.handleChange}
                error={formik.errors.practitionerGivenName}
              />
            </Box>
            <Box className={classes.name}>
              <TextField
                name="practitionerPhoneticFamilyName"
                label="セイ"
                disabled={props.loading}
                value={formik.values.practitionerPhoneticFamilyName}
                onChange={formik.handleChange}
                error={formik.errors.practitionerPhoneticFamilyName}
              />
              <TextField
                name="practitionerPhoneticGivenName"
                label="メイ"
                disabled={props.loading}
                value={formik.values.practitionerPhoneticGivenName}
                onChange={formik.handleChange}
                error={formik.errors.practitionerPhoneticGivenName}
              />
            </Box>
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              type="tel"
              name="practitionerTel"
              label="電話番号"
              disabled={props.loading}
              value={formik.values.practitionerTel}
              onChange={formik.handleChange}
              error={formik.errors.practitionerTel}
            />
            <TextField
              fullWidth
              type="email"
              name="practitionerEmail"
              label="利用開始通知送付先メールアドレス"
              disabled={props.loading}
              value={formik.values.practitionerEmail}
              onChange={formik.handleChange}
              error={formik.errors.practitionerEmail}
            />
          </Box>
          <Box mt={2}>
            <TextField
              name="contractCount"
              label="契約店舗数"
              disabled={props.loading}
              value={formik.values.contractCount}
              onChange={formik.handleChange}
              error={formik.errors.contractCount}
            />
            <SelectPlan
              label="契約プラン"
              name="contractPlanId"
              disabled={props.loading}
              value={formik.values.contractPlanId}
              error={formik.errors.contractPlanId}
              onChange={formik.handleChange}
            />
            <Box marginTop={1}>
              <NativeTextField
                variant="standard"
                disabled
                fullWidth
                id="date"
                name="contractStartDate"
                label="契約開始日"
                type="date"
                value={formik.values.contractStartDate}
                error={!!formik.errors.contractStartDate}
                helperText={formik.errors.contractStartDate}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Typography variant="caption">
                ※意図しない機能が公開される可能性があるため、セットアップURL発行日が設定されます
              </Typography>
            </Box>
          </Box>
        </div>
      )}
    </Formik>
  );
});

SetupDocumentForm.displayName = 'SetupDocumentForm';
