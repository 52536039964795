import { atom } from 'recoil';

import { DeleteModal, ExtendExpiresModal, PageInfo } from './types';

export const setupDocumentsPageInfoState = atom<PageInfo>({
  key: 'setupDocuments.pageInfo',
  default: {
    page: 1,
    perPage: 25,
    totalPage: 1,
  },
});

export const extendExpiresModalState = atom<ExtendExpiresModal>({
  key: 'setupDocuments.extendExpiresModalState',
  default: {
    isOpen: false,
    setupDocumentsCompanyId: null,
  },
});

export const deleteModalState = atom<DeleteModal>({
  key: 'setupDocuments.DeleteModalState',
  default: {
    isOpen: false,
    setupDocumentsCompanyId: null,
  },
});
