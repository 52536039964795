import { NextPage } from 'next';
import React from 'react';

import { AppShell } from '~/components/layouts/AppShell';
import { SetupDocuments } from '~/components/pages/setup_documents';

const SetupDocumentsContainer: NextPage = () => {
  return (
    <AppShell>
      <SetupDocuments />
    </AppShell>
  );
};

export default SetupDocumentsContainer;
