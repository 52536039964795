import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useCallback, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { AlertMessae } from '~/components/partials';
import {
  GetCompanySetupDocumentsDocument,
  useCreateSetupDocumentMutation,
  useGetSetupDocumentPageQuery,
  useSetNewSetupDocumentModalVisiblityMutation,
} from '~/graphql';
import { setupDocumentsPageInfoState } from '~/state/setup_documents/atoms';

import { SetupDocumentForm } from '../SetupDocumentForm';
import { Fields } from '../SetupDocumentForm/types';
import { useDefaultValues } from './use-default_values';

export const NewSetupDocumentModal = () => {
  const formRef = useRef<RefAttributeType<typeof SetupDocumentForm>>(null);
  const defaultValues = useDefaultValues();

  const state = useRecoilValue(setupDocumentsPageInfoState);
  const { data } = useGetSetupDocumentPageQuery();
  const isVisible = data?.setupDocumentPage.newSetupDocumentModal?.isVisible || false;
  const [closeModal] = useSetNewSetupDocumentModalVisiblityMutation({
    variables: { isVisible: false },
  });
  const [createSetupDocument, { loading: creating, error: createError }] =
    useCreateSetupDocumentMutation({
      onCompleted: () => closeModal(),
      refetchQueries: [
        {
          query: GetCompanySetupDocumentsDocument,
          variables: { done: false, page: state.page, perPage: state.perPage },
        },
      ],
    });
  const handleSubmit = useCallback(
    (values: Fields) =>
      createSetupDocument({
        variables: {
          input: {
            ...values,
            contractCount: +values.contractCount,
          },
        },
      }),
    [createSetupDocument],
  );
  const handleSend = useCallback(() => {
    if (!formRef.current) return;
    formRef.current.submitForm();
  }, []);
  const handleClose = useCallback(() => closeModal(), [closeModal]);

  return (
    <Dialog open={isVisible} fullWidth maxWidth="sm">
      <DialogTitle>セットアップURL発行</DialogTitle>
      <DialogContent>
        {createError && <AlertMessae>{createError.message}</AlertMessae>}
        <SetupDocumentForm
          ref={formRef}
          loading={creating}
          initialValue={defaultValues}
          onSubmit={handleSubmit}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={creating} onClick={handleClose}>
          キャンセル
        </Button>
        <Button color="warning" variant="contained" disabled={creating} onClick={handleSend}>
          発行
        </Button>
      </DialogActions>
    </Dialog>
  );
};
